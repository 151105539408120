<template>
  <div>
    <top-decorations/>
    <div class="success-story container" v-if="story">
      <div class="backlink bookmark-container">
        <router-link class="bookmark" to="/success-stories">
          success  <br> stories
        </router-link>
      </div>
      <h1 class="page-title"> {{ story.attributes.title }}</h1>
      <div class="story-image" v-if="story.field_shared_image"><img :src="endpoint+story.field_shared_image.attributes.uri.url" alt=""></div>
      <div v-if="story.attributes.field_shared_description" v-html="story.attributes.field_shared_description.processed"></div>
      <div class="story.sponsor" v-if="story.field_sponsor">
        <div class="label-collaboration">In collaboration with</div>
        {{story.field_sponsor.attributes.title}}
      </div>
      <div class="story-student" v-if="story.field_ref_student">{{story.field_ref_student.attributes.title}}</div>
    </div>
  </div>
</template>
<script>

import TopDecorations from "../components/TopDecorations.vue";
import { fetchNodes } from "@/libs/drupalClient";

export default {
  name: "single-news",
  data() {
    return {
      endpoint: process.env.VUE_APP_ENDPOINT,
      story: null
    };
  },
  components:{
    TopDecorations
  },
  computed: {
    nid() {
      return this.$route.params.nid.split("-").slice("-1")[0];
    },
  },
  methods: {
    fetchStory() {
      fetchNodes('success_story', {
        filters:[
          { 
            key: 'drupal_internal__nid',
            value: this.nid
          }
        ],
        include: [
          'field_ref_student',
          'field_shared_image',
          'field_sponsor'
        ]
      }).then(nodes=>{
        this.story = nodes[0];
      });
    },
  },
  mounted() {
    this.fetchStory();
  },
};
</script>
<style lang="scss" scoped>
.success-story{
  margin-bottom: 80px;
}
.label-collaboration{
  color: #1DC07E;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.story-image{
  margin-bottom: 50px;
}
</style>